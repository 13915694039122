import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import TagList from "../components/TagList";
import BlogRoll from "../components/BlogRoll";

function TagRoute({ pageContext }) {
  const { language, tag } = pageContext;

  return (
    <Layout language={language} navbarBlur={true}>
      <SEO
        keywords={[`Hibot`, `Robotics`, `Tokyo`, `Smart inspection`]}
        title="Hibot - Blog"
      />
      <div className="container py-32">
        <h1 className="text-center">Latest News</h1>
        <TagList language={language} selectedTag={tag} />
        <BlogRoll language={language} selectedTag={tag} />
      </div>
    </Layout>
  );
}

TagRoute.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.object,
    allMarkdownRemark: PropTypes.object,
  }),
  pageContext: PropTypes.object,
};

export default TagRoute;

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
